import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import LayoutNoStripe from "../../components/layoutNoStripe"
import {
    Section,
    SectionHeading,
  } from "../../styledComponents/section"
import HeroBanner from "../../components/HeroBanner"
import { HeroText, HeroHeader } from "../../styledComponents/heroBanner"  
import { ConsultationSection } from "../../styledComponents/contact"
import SkusCoachingProgram from "../../components/Products/SkusCoachingProgram"

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

const DistanceHealingPrices = ({ data, location }) => {
  return (
    <>
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />
    </Helmet>
    <LayoutNoStripe location={location}>

      <HeroBanner 
          image="/distance-healing-karina-grant.jpg" 
          backgroundPosition="55% 0" 
          mediumBackgroundPosition="0 -129px" 
          mediumBackgroundSize="130%" 
          largeBackgroundPosition="0 -160px" 
          largeBackgroundSize="130%"
        >
            <HeroText>
              <HeroHeader>
                  Session Prices
              </HeroHeader>
            </HeroText>
        </HeroBanner>
      <Section>
        <Heading level={1}>
          
          </Heading>
          <ConsultationSection>
            <p>Healing sessions are available with Karina and take place over skype or the phone.</p>
            <p>The cost for one session is £180 per hour.</p>
            <SkusCoachingProgram />
          </ConsultationSection>
      </Section>
    </LayoutNoStripe>
    </>
  )
}

export default DistanceHealingPrices

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    
  }
`
