// src/utils/auth.js
import axios from 'axios';

const auth0Domain = process.env.GATSBY_AUTH0_DOMAIN;

export const getMgmtApiToken = async () => {
  const clientId = process.env.GATSBY_AUTH0_CLIENT_ID;
  const clientSecret = process.env.GATSBY_AUTH0_CLIENT_SECRET;
  const audience = `https://${auth0Domain}/api/v2/`;
  
  console.log('Auth0 Domain:.', auth0Domain);
  console.log('Client ID:', clientId);
  console.log('Client Secret:', clientSecret);
  
  try {
    const response = await axios.post(`https://${auth0Domain}/oauth/token`, {
      client_id: clientId,
      client_secret: clientSecret,
      audience: audience,
      grant_type: 'client_credentials',
    });
    return response.data.access_token;
  } catch (error) {
    console.error('Error obtaining Management API token:', error.response.data);
    throw error;
  }
};