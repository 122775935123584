import React, { useState, useEffect, createContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { GlobalStyle, StyledMain } from "../styledComponents/base";
import CookieNotice from "../components/cookieNotice";
import { useStaticQuery, graphql } from "gatsby";
import "../css/fonts.css";
import Header from "./header";
import FooterContent from "./footer";
import SignupNewsletterForm from "./formNewsletterSignup";
import { StyledSignUpForm } from "../styledComponents/signUpForm";
import SiteNotification from "./siteNotification";
import { StyledWhatsappButton } from "../styledComponents/button";
import Icon from "./icon";

export const AuthContext = createContext({
  isAuthenticated: false,
  user: null,
  roles: [],
});
const LayoutNoStripe = ({
  isHomePage,
  children,
  displayNavBorder,
  hasHero,
  isLander,
  landerType,
  location,
  hideNewsletterSignUp,
  isOffer,
  noHeaderFooter,
}) => {
  const [isCookieSet, setIsCookieSet] = useState(true);
  const [isNotificationSet, setIsNotificationSet] = useState(false);
  const [showWhatsAppButton, setShowWhatsAppButton] = useState(false);
  const [roles, setRoles] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(true);

  const { energyBoost } = useStaticQuery(graphql`
    query EnergyBoostQuery {
      energyBoost: allWpEvent(
        sort: { fields: eventsMetaData___datetime, order: ASC }
        filter: { eventsMetaData: { eventpassed: { ne: true } }, eventsTag: { nodes: { elemMatch: { slug: { eq: "quantum-touch-energy-boost" } } } } }
        limit: 1
      ) {
        nodes {
          title
          uri
          id
          eventsMetaData {
            price
            usPrice
            datetime
            timezone
          }
        }
      }
    }
  `);

  const { isAuthenticated, user, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
  // console.log("AuthContext Provider Values: ", { isAuthenticated, user, roles });

  const fetchRoles = async () => {
    if (!isAuthenticated) {
      setRoles([]);
      setLoadingRoles(false);
      return;
    }

    try {
      const userRoles = user?.["https://www.karinagrant.co.uk/roles"] || [];

      if (userRoles.length > 0) {
        setRoles(userRoles);
      } else {
        const token = await getAccessTokenSilently({ ignoreCache: true });
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const tokenRoles = decodedToken["https://www.karinagrant.co.uk/roles"] || [];
        setRoles(tokenRoles);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
      setRoles([]);
    } finally {
      setLoadingRoles(false);
    }
  };

  useEffect(() => {
    fetchRoles();

    const interval = setInterval(() => {
      fetchRoles();
    }, 15 * 1000);

    return () => clearInterval(interval);
  }, [isAuthenticated, user, getAccessTokenSilently]);

  const checkIfCookieExists = () => {
    document.cookie.split(";").some((item) => item.trim().startsWith("cookie-policy="))
      ? setIsCookieSet(true)
      : setIsCookieSet(false);
  };

  const createCookie = (value) => {
    document.cookie = `${value}=true; path=/; host=${process.env.GATSBY_DOMAIN_NAME}; ${
      process.env.GATSBY_SECURE_COOKIE ? "secure;" : ""
    }`;
    setIsCookieSet(true);
  };

  const createNotificationCookie = (value) => {
    document.cookie = `${value}=true; path=/; host=${process.env.GATSBY_DOMAIN_NAME}; ${
      process.env.GATSBY_SECURE_COOKIE ? "secure;" : ""
    }`;
    setIsNotificationSet(true);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      checkIfCookieExists();
      if (isHomePage) {
        setTimeout(() => setShowWhatsAppButton(true), 3000);
      } else {
        setShowWhatsAppButton(true);
      }
    }
  }, [isHomePage]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, roles, logout, loginWithRedirect }}>
      {/* {console.log('isAuthenticated',isAuthenticated)} */}
      {/* {console.log('user',user)} */}
      {/* {console.log('roles',roles)} */}
      <GlobalStyle />
      {!isCookieSet && !isLander && !noHeaderFooter && <CookieNotice isCookieSet={isCookieSet} createCookie={createCookie} />}
      {process.env.GATSBY_NOTIFICATION_BANNER === "true" && !isNotificationSet && (
        <SiteNotification isNotificationSet={isNotificationSet} createNotificationCookie={createNotificationCookie} energyBoostData={energyBoost.energyBoost} />
      )}
      <div className="global-wrapper" data-is-root-path={isHomePage}>
        {!isLander && !noHeaderFooter && <Header displayNavBorder={displayNavBorder} hasHero={hasHero} />}
        <StyledMain hasHero={hasHero} isOffer={isOffer} landerType={landerType}>
        {/* {loadingRoles ? (
            <p>Loading user roles...</p>
          ) : (
            <div>
              {isAuthenticated ? (
                <>
                  <p>Welcome, {user?.name || "User"}!</p>
                  <p>Your roles: {roles.join(", ") || "None"}</p>
                  <button onClick={() => logout({ returnTo: window.location.origin })}>Logout</button>
                </>
              ) : (
                <button onClick={loginWithRedirect}>Login</button>
              )}
            </div>
          )} */}
          {children}
        </StyledMain>
        {!isHomePage && !isLander && !hideNewsletterSignUp && !noHeaderFooter && (
          <StyledSignUpForm>
            <SignupNewsletterForm createCookie={createCookie} />
          </StyledSignUpForm>
        )}
        {!isLander && !noHeaderFooter ? <FooterContent isCookieSet={isCookieSet} /> : null}
        {showWhatsAppButton && !isLander && !noHeaderFooter && (
          <StyledWhatsappButton target="_blank" href="https://api.whatsapp.com/send/?app_absent=0&amp;phone=447457405084">
            <Icon name="whatsapp" /> <span>Chat to Tracy</span>
          </StyledWhatsappButton>
        )}
      </div>
    </AuthContext.Provider>
  );
};

export default LayoutNoStripe;
