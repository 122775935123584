import axios from 'axios';
import { getMgmtApiToken } from './auth';

const auth0Domain = process.env.GATSBY_AUTH0_DOMAIN;

export const handleUser = async (email, roleId, shouldProcess) => {
  if (!shouldProcess) {
    console.log("Processing condition not met. Skipping user handling.");
    return;
  }

  try {
    const managementApiToken = await getMgmtApiToken();

    // Check if user exists
    const userResponse = await axios.get(`https://${auth0Domain}/api/v2/users-by-email`, {
      params: { email },
      headers: {
        Authorization: `Bearer ${managementApiToken}`,
      },
    });

    let user = userResponse.data[0];

    if (user) {
      // User exists, assign role
      await axios.post(
        `https://${auth0Domain}/api/v2/users/${user.user_id}/roles`,
        { roles: [roleId] },
        { headers: { Authorization: `Bearer ${managementApiToken}` } }
      );
      console.log(`Role ${roleId} assigned to existing user ${email}.`);
    } else {
      // User does not exist, create user
      const newUserResponse = await axios.post(
        `https://${auth0Domain}/api/v2/users`,
        {
          email,
          connection: "Username-Password-Authentication",
          password: Math.random().toString(36).slice(-8), // Generate a random password
        },
        { headers: { Authorization: `Bearer ${managementApiToken}` } }
      );

      user = newUserResponse.data;
      console.log('user',user)
      // Assign role to the new user
      await axios.post(
        `https://${auth0Domain}/api/v2/users/${user.user_id}/roles`,
        { roles: [roleId] },
        { headers: { Authorization: `Bearer ${managementApiToken}` } }
      );

      // Send verification email
      await axios.post(
        `https://${auth0Domain}/api/v2/jobs/verification-email`,
        { user_id: user.user_id },
        { headers: { Authorization: `Bearer ${managementApiToken}` } }
      );

      console.log(`New user ${email} created and role ${roleId} assigned.`);
    }

    return user;
  } catch (error) {
    console.error("Error handling user:", error);
    throw error;
  }
};