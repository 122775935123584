import React, { useState, useEffect } from "react";
import { graphql, navigate } from "gatsby";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Article,
  ArticleHeader,
  ArticleSection,
} from "../styledComponents/article";
import LayoutNoStripe from "../components/layoutNoStripe";
import PostPaymentHandler from "../utils/postPaymentHandler";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const PaymentSuccessAudioProgram = ({ data, location }) => {
  const [customerFirstName, setCustomerFirstName] = useState(""); 
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [shouldProcess, setShouldProcess] = useState(false);
  const [customer, setCustomer] = useState({});
  const [session, setSession] = useState({});
  const [roleId, setRoleId] = useState([]);

  const getStripeSession = async (myParam) => {
    try {
      const response = await fetch(`/.netlify/functions/stripe-customer?sessionID=${myParam}`);
      const data = await response.json();
      setSession(data.msg);
      setCustomer(data.customer);
      // setProducts(data.productsList);
      setShouldProcess(true);
      setRoleId(data.productsList[0]?.metadata?.auth0_role || "default")

      const fullName = data.customer.name;
      const firstName = fullName.split(" ")[0];
      setCustomerFirstName(firstName);

    } catch (error) {
      console.error("Error fetching Stripe session:", error);
      setMessage('An error occurred while fetching your session. Please try again.');
      setMessageType('error');
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("sessionID");
    if (myParam) {
      getStripeSession(myParam);
    }
  }, []);

  return (
    <LayoutNoStripe displayNavBorder location={location}>
      <Article itemScope itemType="http://schema.org/Article">
        <ArticleHeader noMargin>
          <h1 itemProp="headline">Payment Success</h1>
        </ArticleHeader>
        <ArticleSection itemProp="articleBody">
          {message && (
            <div className={`message ${messageType}`}>
              {message}
            </div>
          )}
          <p>Thank you {customerFirstName || "Customer"} for your purchase! We are processing your order. You shall receive an email from Tracy within the next 24 hours.</p>
          {shouldProcess && (
            <PostPaymentHandler
              email={customer.email} // Use the actual email from the Stripe session
              roleId={roleId}
              shouldProcess={shouldProcess}
              setMessage={setMessage}
              setMessageType={setMessageType}
            />
          )}
        </ArticleSection>
      </Article>
    </LayoutNoStripe>
  );
};

export default PaymentSuccessAudioProgram;