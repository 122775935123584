import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import axios from "axios"
import { navigate } from "gatsby"
import {
  StyledForm,
  StyledFieldset,
  StyledInput,
  StyledLabel,
  StyledLabelHeader,
  StyledFormElementWrapper,
  StyledTextArea,
  ErrorMessage,
  StyledSelect,
  StyledSelectWrapper
} from "../styledComponents/contact"
import { StyledButton } from "../styledComponents/button"
import EventDates from "./EventDates"
import AudioProgramSelectOptions from "./AudioProgramSelectOptions"

const FeedbackForm = () => {
  const [fbType, setfbType] = useState({})
  const [token, setToken] = useState("") // store token
  const [isSuccessMessage, setIsSuccessMessage] = useState(false) // manage is success message state
  const [messageSent, setMessageSent] = useState(false) // manage sent message state
  const [isFormValid, setIsFormValid] = useState(false)

  // Validation
  const validate = values => {
    const errors = {}
    if (!values.firstName) {
      errors.firstName = "Required"
    }
    if (!values.lastName) {
      errors.lastName = "Required"
    }
    if (!values.email) {
      errors.email = "Required"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address"
    }
    if (!values.feedbackType || !fbType ) {
      errors.feedbackType = "Required"
    }

    if (values.feedbackType === 'client') {
      if(!values.primaryReasonForSession){
        errors.primaryReasonForSession = "Required"
      }
      if(!values.RatePainBefore){
        errors.RatePainBefore = "Required"
      }
      if(!values.RatePainAfter){
        errors.RatePainAfter = "Required"
      }
      if(!values.modalityType){
        errors.modalityType = "Required"
      }
      if(!values.anonymousNameClient){
        errors.anonymousNameClient = "Required"
      }
    }

    if (values.feedbackType === 'workshop') {
      
      if(!values.workshopdate){
        errors.workshopdate = "Required"
      }
      if(!values.SuggestCourseInstructorToPeer){
        errors.SuggestCourseInstructorToPeer = ""
      }
      if(!values.OverallExperience){
        errors.OverallExperience = "Required"
      }
      if(!values.CourseExperienceShift){
        errors.CourseExperienceShift = "Required"
      }      

      if(!values.anonymousNameWorkshop){
        errors.anonymousNameWorkshop = "Required"
      }
    }

    if (values.feedbackType === 'audioProgram' || fbType === 'audio') {
      if(!values.audioProgramFeedback){
        errors.audioProgramFeedback = "Required"
      }
      if(!values.AudioQuality){
        errors.AudioQuality = ""
      }
      if(!values.howOftenListen){
        errors.howOftenListen = ""
      }
      if(!values.SuggestAudioToPeer){
        errors.SuggestAudioToPeer = ""
      }
      if(!values.AudioOverallExperience){
        errors.AudioOverallExperience = ""
      }
      if(!values.audioFeebackComment){
        errors.audioFeebackComment = ""
      }
    }

    Object.keys(errors).length ? setIsFormValid(false) : setIsFormValid(true)
    return errors
  }

  // this effect function authenticates our subcriber user to get a token
  useEffect(() => {
    axios({
      method: "post",
      url: `https://adminwp.karinagrant.co.uk/wp-json/jwt-auth/v1/token`,
      data: {
        username: `contactform`, // provide a user credential with subscriber role
        password: `qR9zizJq^qKbv2#5nopD8ly#`,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setToken(response.data.token)
      })
      .catch(error => console.error("Error", error))
  }, [])

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const {
    handleChange,
    handleBlur,
    isSubmitting,
    values,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      feedbackType: fbType || "",
      primaryReasonForSession: "",
      RatePainBefore: "",
      RatePainAfter: "",
      modalityType: "",
      workshopdate: "",
      SuggestCourseInstructorToPeer: "",
      OverallExperience: "",
      CourseExperienceShift: "",
      anonymousNameClient: "",
      anonymousNameWorkshop: "",
      workshopFeebackComment: "",
      clientFeebackComment: "",
      audioProgramFeedback: "",
      AudioQuality: "",
      AudioOverallExperience: "",
      howOftenListen: "",
      SuggestAudioToPeer: "",
      RateAudioBefore: "",
      RateAudioAfter: "",
      audioFeebackComment: "",
    },
    validate,
    onSubmit: (
      {
        firstName,
        lastName,
        email,
        feedbackType,
        primaryReasonForSession,
        RatePainBefore,
        RatePainAfter,
        modalityType,
        SuggestCourseInstructorToPeer,
        OverallExperience,
        CourseExperienceShift,
        workshopdate,
        anonymousNameClient,
        anonymousNameWorkshop,
        workshopFeebackComment,
        clientFeebackComment,
        audioProgramFeedback,
        AudioOverallExperience,
        AudioQuality,
        howOftenListen,
        SuggestAudioToPeer,
        RateAudioBefore,
        RateAudioAfter,
        audioFeebackComment,
      },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)
      // addToMailchimp(values.email, {
      //   firstName: values.firstName, 
      //   lastName: values.lastName,
      //   telephone: values.telephone,
      //   dob: values.dob.replace('/','-'),
      //   feedbackType: values.feedbackType,
      //   occupation: values.occupation,
      //   primaryRea: values.primaryRea,
      //   symptomsSt: values.symptomsSt,
      //   medication: values.medication,
      //   hadQtBefor: values.hadQtBefor,
      //   howHear: values,howHear,
      //   'group[380946][512]':'512'
      //   }
      // )
      const bodyFormData = new FormData()
      bodyFormData.set("firstName", firstName)
      bodyFormData.set("lastName", lastName)
      bodyFormData.set("email", email)
      bodyFormData.set("feedbackType", feedbackType)
      bodyFormData.set("primaryReasonForSession", primaryReasonForSession)
      bodyFormData.set("RatePainBefore", RatePainBefore)
      bodyFormData.set("RatePainAfter", RatePainAfter)
      bodyFormData.set("modalityType", modalityType)
      bodyFormData.set("SuggestCourseInstructorToPeer", SuggestCourseInstructorToPeer)
      bodyFormData.set("OverallExperience", OverallExperience)
      bodyFormData.set("CourseExperienceShift", CourseExperienceShift)
      bodyFormData.set("workshopdate", workshopdate)
      bodyFormData.set("anonymousNameClient", anonymousNameClient)
      bodyFormData.set("anonymousNameWorkshop", anonymousNameWorkshop)
      bodyFormData.set("workshopFeebackComment", workshopFeebackComment)
      bodyFormData.set("clientFeebackComment", clientFeebackComment)
      bodyFormData.set("audioProgramFeedback", audioProgramFeedback)
      bodyFormData.set("AudioOverallExperience", AudioOverallExperience)
      bodyFormData.set("AudioQuality", AudioQuality)
      bodyFormData.set("howOftenListen", howOftenListen)
      bodyFormData.set("SuggestAudioToPeer", SuggestAudioToPeer)
      bodyFormData.set("RateAudioBefore", RateAudioBefore)
      bodyFormData.set("RateAudioAfter", RateAudioAfter)
      bodyFormData.set("audioFeebackComment", audioFeebackComment)
      
      // here we sent
      axios({
        method: "post",
        url: `https://adminwp.karinagrant.co.uk/wp-json/contact-form-7/v1/contact-forms/8085/feedback`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          // actions taken when submission goes OK
          resetForm()
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(true)
          navigate("/feedback/thank-you")
        })
        .catch(error => {
          // actions taken when submission goes wrong
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(false)
        })
    },
  })

  const checkIfHasQuery = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const FbType = urlParams.get('type')
    if(FbType){
      setfbType(FbType)
    }
  }

  const isChecked = () => {
    if((fbType === 'audio') || (values.feedbackType === "audioProgram")){
      return 'checked'
    }
  }

  useEffect(() => {
    checkIfHasQuery()
    // set timeout 3 seconds to remove error/success message.
    setTimeout(() => {
      // this will reset messageSent and isSuccessMessage state
      setMessageSent(false)
      setIsSuccessMessage(false)
    }, 3000)
    // this effect function will be dispatched when isSuccessMessage or messageSent changes its state
  }, [isSuccessMessage, messageSent])
  
  return (
    
    <>
    <StyledForm onSubmit={handleSubmit}>
      {(values.feedbackType === "audioProgram" || fbType === 'audio') ?
      <p style={{'marginBottom':'1.6rem'}}>Welcome. This form will take 2 minutes to fill in and it helps me to imporve my meditations. Thank you for your time, I appreciate it so much.</p>
      :
      <>
      <p style={{'marginBottom':'1.6rem'}}>Welcome. This form will take 2 minutes to fill in and it helps me to inspire even more people to learn these transformational techniques. </p>
      <p style={{'marginBottom':'1.6rem'}}>Thank you for your time, I appreciate it so much.</p>
      </>
      } 
    
    <StyledFieldset>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="firstName">
            <StyledLabelHeader
              required
              isEmpty={errors.firstName && touched.firstName}
            >
              First Name
            </StyledLabelHeader>
            <StyledInput
              id="firstName"
              name="firstName"
              type="text"
              onChange={handleChange}
              value={values.firstName}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.firstName && touched.firstName ? (
            <span className="error-message">{errors.firstName}</span>
          ) : null}
        </StyledFormElementWrapper>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="lastName">
            <StyledLabelHeader
              required
              isEmpty={errors.lastName && touched.lastName}
            >
              Last Name
            </StyledLabelHeader>
            <StyledInput
              id="lastName" 
              name="lastName"
              type="text"
              onChange={handleChange}
              value={values.lastName}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.lastName && touched.lastName ? (
            <span className="error-message">{errors.lastName}</span>
          ) : null}
        </StyledFormElementWrapper>      
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="email">
            <StyledLabelHeader
                required
                isEmpty={errors.email && touched.email}
              > 
                Email Address
            </StyledLabelHeader>
            <StyledInput
                id="email"
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                
                onBlur={handleBlur("email")}
                required
              />
            </StyledLabel>
          {errors.email && touched.email ? (
            <span className="error-message">{errors.email}</span>
          ) : null}
      </StyledFormElementWrapper>

      <StyledFormElementWrapper>
          <StyledLabelHeader
            required
            className="radiolist"
            isEmpty={errors.feedbackType && touched.feedbackType}
            >Feeback for?</StyledLabelHeader>
            <ul role="group" aria-labelledby="my-radio-group">
              <li>
                <StyledLabel htmlFor="client">
                  <StyledInput
                    type="radio"
                    id="client"
                    name="feedbackType"
                    value="client"
                    checked={values.feedbackType === "client" || fbType === 'client'}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                  />
                  <span>Treatment</span>
                </StyledLabel>
              </li>
              <li>
                <StyledLabel htmlFor="workshop">
                <StyledInput
                    type="radio"
                    id="workshop"
                    name="feedbackType"
                    value="workshop"
                    checked={values.feedbackType === "workshop" || fbType === 'workshop'}
                    // checked={isChecked()}
                    onChange={handleChange}
                    //onBlur={handleBlur}
                  />
                  <span>Workshop</span>
                </StyledLabel>
              </li>
              <li>
                <StyledLabel htmlFor="audioProgram">
                <StyledInput
                    type="radio"
                    id="audioProgram"
                    name="feedbackType"
                    value="audioProgram"
                    checked={values.feedbackType === "audioProgram" || fbType === 'audioProgram'}
                    // checked={isChecked()}
                    onChange={handleChange}
                    //onBlur={handleBlur}
                  />
                  <span>Audio / program</span>
                </StyledLabel>
              </li>
            </ul>
            {errors.feedbackType && touched.feedbackType ? (
            <span className="error-message">{errors.feedbackType}</span>
          ) : null}
        </StyledFormElementWrapper>

        {(values.feedbackType == "client" || fbType == 'client') && 
        
        <>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="primaryReasonForSession">
            <StyledLabelHeader
            required
            isEmpty={errors.primaryReasonForSession && touched.primaryReasonForSession}
            >Primary reason for session</StyledLabelHeader>
            <StyledTextArea
              id="primaryReasonForSession"
              name="primaryReasonForSession"
              type="text"
              onChange={handleChange}
              value={values.primaryReasonForSession}
              placeholder="(e.g pain in shoulder)"
            />
          </StyledLabel>
          {/* {errors.primaryReasonForSession && touched.primaryReasonForSession ? (
            <span className="error-message">{errors.primaryReasonForSession}</span>
          ) : null} */}
        </StyledFormElementWrapper>
        
        <StyledFormElementWrapper>
          <StyledLabelHeader
            required
            className="radiolist"
            isEmpty={errors.RatePainBefore && touched.RatePainBefore}
            >Rate your pain/issue before?</StyledLabelHeader>
            <ul role="group" aria-labelledby="my-radio-group" className="rating-pain-list">
              <li>
                <StyledLabel htmlFor="RatePainBefore1">
                  <StyledInput
                    type="radio"
                    id="RatePainBefore1"
                    name="RatePainBefore"
                    value="No pain"
                    checked={values.RatePainBefore === "No pain"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>1</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="RatePainBefore2">
                  <StyledInput
                    type="radio"
                    id="RatePainBefore2"
                    name="RatePainBefore"
                    value="Mild"
                    checked={values.RatePainBefore === "Mild"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>2</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="RatePainBefore3">
                  <StyledInput
                    type="radio"
                    id="RatePainBefore3"
                    name="RatePainBefore"
                    value="Moderate"
                    checked={values.RatePainBefore === "Moderate"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>3</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="RatePainBefore4">
                  <StyledInput
                    type="radio"
                    id="RatePainBefore4"
                    name="RatePainBefore"
                    value="Server"
                    checked={values.RatePainBefore === "Server"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>4</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="RatePainBefore5">
                  <StyledInput
                    type="radio"
                    id="RatePainBefore5"
                    name="RatePainBefore"
                    value="Very server"
                    checked={values.RatePainBefore === "Very server"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>5</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="RatePainBefore6">
                  <StyledInput
                    type="radio"
                    id="RatePainBefore6"
                    name="RatePainBefore"
                    value="Worst pain"
                    checked={values.RatePainBefore === "Worst pain"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>6</span>
                </StyledLabel>
              </li>
            </ul>
            <PainReferenceScale />
            {errors.RatePainBefore && touched.RatePainBefore ? (
            <span className="error-message">{errors.RatePainBefore}</span>
          ) : null}
        </StyledFormElementWrapper>

        <StyledFormElementWrapper>
          <StyledLabelHeader
            required
            className="radiolist"
            isEmpty={errors.RatePainAfter && touched.RatePainAfter}
            >Rate your pain/issue after?</StyledLabelHeader>
            <ul role="group" aria-labelledby="my-radio-group" className="rating-pain-list">
              <li>
                <StyledLabel htmlFor="RatePainAfter1">
                  <StyledInput
                    type="radio"
                    id="RatePainAfter1"
                    name="RatePainAfter"
                    value="No pain"
                    checked={values.RatePainAfter === "No pain"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>1</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="RatePainAfter2">
                  <StyledInput
                    type="radio"
                    id="RatePainAfter2"
                    name="RatePainAfter"
                    value="Mild"
                    checked={values.RatePainAfter === "Mild"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>2</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="RatePainAfter3">
                  <StyledInput
                    type="radio"
                    id="RatePainAfter3"
                    name="RatePainAfter"
                    value="Moderate"
                    checked={values.RatePainAfter === "Moderate"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>3</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="RatePainAfter4">
                  <StyledInput
                    type="radio"
                    id="RatePainAfter4"
                    name="RatePainAfter"
                    value="Severe"
                    checked={values.RatePainAfter === "Servere"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>4</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="RatePainAfter5">
                  <StyledInput
                    type="radio"
                    id="RatePainAfter5"
                    name="RatePainAfter"
                    value="Very severe"
                    checked={values.RatePainAfter === "Very severe"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>5</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="RatePainAfter6">
                  <StyledInput
                    type="radio"
                    id="RatePainAfter6"
                    name="RatePainAfter"
                    value="Worst pain"
                    checked={values.RatePainAfter === "Worst pain"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>6</span>
                </StyledLabel>
              </li>
            </ul>
            <PainReferenceScale />
            {errors.RatePainAfter && touched.RatePainAfter ? (
            <span className="error-message">{errors.RatePainAfter}</span>
          ) : null}
        </StyledFormElementWrapper>
        

        <StyledFormElementWrapper>
          <StyledLabelHeader
            required
            className="radiolist"
            isEmpty={errors.modalityType && touched.modalityType}
            >Which modality did Karina use?</StyledLabelHeader>
            <ul role="group" aria-labelledby="my-radio-group" className="modality-type">
              <li>
                <StyledLabel htmlFor="qt">
                  <StyledInput
                    type="radio"
                    id="qt"
                    name="modalityType"
                    value="qt"
                    checked={values.modalityType === "qt"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>Quantum-Touch</span>
                </StyledLabel>
              </li>
              <li>
                <StyledLabel htmlFor="pat">
                <StyledInput
                    type="radio"
                    id="pat"
                    name="modalityType"
                    value="pat"
                    checked={values.modalityType === "pat"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>Permissioning &amp; Transformatio™n</span>
                </StyledLabel>
              </li>
              <li>
                <StyledLabel htmlFor="both">
                <StyledInput
                    type="radio"
                    id="both"
                    name="modalityType"
                    value="both"
                    checked={values.modalityType === "both"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>Both</span>
                </StyledLabel>
              </li>
              <li>
                <StyledLabel htmlFor="unsure">
                <StyledInput
                    type="radio"
                    id="unsure"
                    name="modalityType"
                    value="unsure"
                    checked={values.modalityType === "unsure"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>Unsure</span>
                </StyledLabel>
              </li>
            </ul>
            {errors.modalityType && touched.modalityType ? (
            <span className="error-message">{errors.modalityType}</span>
          ) : null}
        </StyledFormElementWrapper>
        

        <StyledFormElementWrapper>
          <StyledLabel htmlFor="clientFeebackComment">
            <StyledLabelHeader>Karina will be grateful if you will write a few sentences here about your healing experience and if you experienced a reduction in your symptoms. This will be put on the healing testimonials page of her website to encourage others to try a healing session.</StyledLabelHeader>
            <StyledTextArea
              id="clientFeebackComment"
              name="clientFeebackComment"
              type="text"
              onChange={handleChange}
              value={values.clientFeebackComment}
              placeholder="Please leave a comment here"
            />
          </StyledLabel>
        </StyledFormElementWrapper>
        
        <StyledFormElementWrapper>
          <StyledLabelHeader
            required
            className="radiolist"
            isEmpty={errors.anonymousNameClient && touched.anonymousNameClient}
            >Remain anonymous?</StyledLabelHeader>
            <ul role="group" aria-labelledby="my-radio-group" className="modality-type">
              <li>
                <StyledLabel htmlFor="anonymousYes">
                  <StyledInput
                    type="radio"
                    id="anonymousYes"
                    name="anonymousNameClient"
                    value="Yes"
                    checked={values.anonymousNameClient === "Yes"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>Yes</span>
                </StyledLabel>
              </li>
              <li>
                <StyledLabel htmlFor="anonymousNo">
                <StyledInput
                    type="radio"
                    id="anonymousNo"
                    name="anonymousNameClient"
                    value="No"
                    checked={values.anonymousNameClient === "No"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>No</span>
                </StyledLabel>
              </li>
            </ul>
            {errors.anonymousNameClient && touched.anonymousNameClient ? (
            <span className="error-message">{errors.anonymousNameClient}</span>
          ) : null}
        </StyledFormElementWrapper>
        </>
       }

       {(values.feedbackType === "workshop" || fbType === 'workshop') && 
        <>
        
         <StyledSelectWrapper>
          <StyledFormElementWrapper>
            <StyledLabel htmlFor="workshopdate">
            <StyledLabelHeader
                required
                isEmpty={errors.workshopdate && touched.workshopdate}
              > 
                Please select workshop
              </StyledLabelHeader>
              <StyledSelect
                  defaultValue="default"
                  id="workshopdate"
                  name="workshopdate"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  >
                  <EventDates type="past"/>
                </StyledSelect>
            </StyledLabel>
            {errors.workshopdate && touched.workshopdate ? (
              <span className="error-message">{errors.workshopdate}</span>
            ) : null}
          </StyledFormElementWrapper>
        </StyledSelectWrapper>

        

        <StyledFormElementWrapper>
          <StyledLabelHeader
            required
            className="radiolist"
            isEmpty={errors.OverallExperience && touched.OverallExperience}
            >I rate my overall course experience as excellent.</StyledLabelHeader>
            <ul role="group" aria-labelledby="my-radio-group" className="rating-list">
              <li>
                <StyledLabel htmlFor="OverallExperience1">
                  <StyledInput
                    type="radio"
                    id="OverallExperience1"
                    name="OverallExperience"
                    value="Strongly agree"
                    checked={values.OverallExperience === "Strongly agree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>1</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="OverallExperience2">
                  <StyledInput
                    type="radio"
                    id="OverallExperience2"
                    name="OverallExperience"
                    value="Agree"
                    checked={values.OverallExperience === "Agree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>2</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="OverallExperience3">
                  <StyledInput
                    type="radio"
                    id="OverallExperience3"
                    name="OverallExperience"
                    value="Neuteral"
                    checked={values.OverallExperience === "Neuteral"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>3</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="OverallExperience4">
                  <StyledInput
                    type="radio"
                    id="OverallExperience4"
                    name="OverallExperience"
                    value="Disagree"
                    checked={values.OverallExperience === "Disagree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>4</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="OverallExperience5">
                  <StyledInput
                    type="radio"
                    id="OverallExperience5"
                    name="OverallExperience"
                    value="Strongly disagree"
                    checked={values.OverallExperience === "Strongly disagree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>5</span>
                </StyledLabel>
              </li>
            </ul>
            <FeedbackScale />
            {errors.OverallExperience && touched.OverallExperience ? (
            <span className="error-message">{errors.OverallExperience}</span>
          ) : null}
        </StyledFormElementWrapper>


        <StyledFormElementWrapper>
          <StyledLabelHeader
            required
            className="radiolist"
            isEmpty={errors.SuggestCourseInstructorToPeer && touched.SuggestCourseInstructorToPeer}
            >I would recommend this course and instructor to a friend</StyledLabelHeader>
            <ul role="group" aria-labelledby="my-radio-group" className="rating-list">
              <li>
                <StyledLabel htmlFor="SuggestCourseInstructorToPeer1">
                  <StyledInput
                    type="radio"
                    id="SuggestCourseInstructorToPeer1"
                    name="SuggestCourseInstructorToPeer"
                    value="Strongly agree"
                    checked={values.SuggestCourseInstructorToPeer === "Strongly agree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>1</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="SuggestCourseInstructorToPeer2">
                  <StyledInput
                    type="radio"
                    id="SuggestCourseInstructorToPeer2"
                    name="SuggestCourseInstructorToPeer"
                    value="Agree"
                    checked={values.SuggestCourseInstructorToPeer === "Agree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>2</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="SuggestCourseInstructorToPeer3">
                  <StyledInput
                    type="radio"
                    id="SuggestCourseInstructorToPeer3"
                    name="SuggestCourseInstructorToPeer"
                    value="Neuteral"
                    checked={values.SuggestCourseInstructorToPeer === "Neuteral"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>3</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="SuggestCourseInstructorToPeer4">
                  <StyledInput
                    type="radio"
                    id="SuggestCourseInstructorToPeer4"
                    name="SuggestCourseInstructorToPeer"
                    value="Disagree"
                    checked={values.SuggestCourseInstructorToPeer === "Disagree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>4</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="SuggestCourseInstructorToPeer5">
                  <StyledInput
                    type="radio"
                    id="SuggestCourseInstructorToPeer5"
                    name="SuggestCourseInstructorToPeer"
                    value="Strongly disagree"
                    checked={values.SuggestCourseInstructorToPeer === "Strongly disagree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>5</span>
                </StyledLabel>
              </li>
            </ul>
            <FeedbackScale />
            {errors.SuggestCourseInstructorToPeer && touched.SuggestCourseInstructorToPeer ? (
            <span className="error-message">{errors.SuggestCourseInstructorToPeer}</span>
          ) : null}
        </StyledFormElementWrapper>

        <StyledFormElementWrapper>
          <StyledLabelHeader
            required
            className="radiolist"
            isEmpty={errors.CourseExperienceShift && touched.CourseExperienceShift}
            >I experienced a shift in the healing practice sessions. This may be that pain was reduced/eliminated and/or an emotional shift.</StyledLabelHeader>
            <ul role="group" aria-labelledby="my-radio-group" className="rating-list">
              <li>
                <StyledLabel htmlFor="CourseExperienceShift1">
                  <StyledInput
                    type="radio"
                    id="CourseExperienceShift1"
                    name="CourseExperienceShift"
                    value="Strongly agree"
                    checked={values.CourseExperienceShift === "Strongly agree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>1</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="CourseExperienceShift2">
                  <StyledInput
                    type="radio"
                    id="CourseExperienceShift2"
                    name="CourseExperienceShift"
                    value="Agree"
                    checked={values.CourseExperienceShift === "Agree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>2</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="CourseExperienceShift3">
                  <StyledInput
                    type="radio"
                    id="CourseExperienceShift3"
                    name="CourseExperienceShift"
                    value="Neuteral"
                    checked={values.CourseExperienceShift === "Neuteral"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>3</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="CourseExperienceShift4">
                  <StyledInput
                    type="radio"
                    id="CourseExperienceShift4"
                    name="CourseExperienceShift"
                    value="Disagree"
                    checked={values.CourseExperienceShift === "Disagree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>4</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="CourseExperienceShift5">
                  <StyledInput
                    type="radio"
                    id="CourseExperienceShift5"
                    name="CourseExperienceShift"
                    value="Strongly disagree"
                    checked={values.CourseExperienceShift === "Strongly disagree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>5</span>
                </StyledLabel>
              </li>
            </ul>
            <FeedbackScale />
            {errors.CourseExperienceShift && touched.CourseExperienceShift ? (
            <span className="error-message">{errors.CourseExperienceShift}</span>
          ) : null}
        </StyledFormElementWrapper>

        <StyledFormElementWrapper>
          <StyledLabel htmlFor="workshopFeebackComment">
            <StyledLabelHeader>Please take the time to write a few sentences about any physical/emotional positive shifts you experienced in the workshop. This will be put on Karina’s website to encourage others to learn these transformational skills &amp; techniques.</StyledLabelHeader>
            <StyledTextArea
              id="workshopFeebackComment"
              name="workshopFeebackComment"
              type="text"
              onChange={handleChange}
              value={values.workshopFeebackComment}
            />
          </StyledLabel>
        </StyledFormElementWrapper>
        
        <StyledFormElementWrapper>
          <StyledLabelHeader
            required
            className="radiolist"
            isEmpty={errors.anonymousNameWorkshop && touched.anonymousNameWorkshop}
            >Remain anonymous?</StyledLabelHeader>
            <ul role="group" aria-labelledby="my-radio-group" className="modality-type">
              <li>
                <StyledLabel htmlFor="anonymousWorkshopYes">
                  <StyledInput
                    type="radio"
                    id="anonymousWorkshopYes"
                    name="anonymousNameWorkshop"
                    value="Yes"
                    checked={values.anonymousNameWorkshop === "Yes"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>Yes</span>
                </StyledLabel>
              </li>
              <li>
                <StyledLabel htmlFor="anonymousWorkshopNo">
                <StyledInput
                    type="radio"
                    id="anonymousWorkshopNo"
                    name="anonymousNameWorkshop"
                    value="No"
                    checked={values.anonymousNameWorkshop === "No"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>No</span>
                </StyledLabel>
              </li>
            </ul>
            {errors.anonymousNameWorkshop && touched.anonymousNameWorkshop ? (
            <span className="error-message">{errors.anonymousNameWorkshop}</span>
          ) : null}
        </StyledFormElementWrapper>
        </>
       }

       {
       (values.feedbackType === "audioProgram" || fbType === 'audio') && 
      
        <>
          <StyledSelectWrapper>
          <StyledFormElementWrapper>
            <StyledLabel htmlFor="audioProgramFeedback">
            <StyledLabelHeader
                required
                isEmpty={errors.audioProgramFeedback && touched.audioProgramFeedback}
              > 
                Which audio are you giving feedback for?
              </StyledLabelHeader>
              <StyledSelect
                  defaultValue="default"
                  id="audioProgramFeedback"
                  name="audioProgramFeedback"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  >
                  <AudioProgramSelectOptions />
                </StyledSelect>
            </StyledLabel>
            {errors.audioProgramFeedback && touched.audioProgramFeedback ? (
              <span className="error-message">{errors.audioProgramFeedback}</span>
            ) : null}
          </StyledFormElementWrapper>
        </StyledSelectWrapper>
        
        <StyledFormElementWrapper>
          <StyledLabelHeader
            required
            className="radiolist"
            isEmpty={errors.RateAudioBefore && touched.RateAudioBefore}
            >How did you feel before listening?</StyledLabelHeader>
            <ul role="group" aria-labelledby="my-radio-group" className="rating-list">
              <li>
                <StyledLabel htmlFor="RateAudioBefore1">
                  <StyledInput
                    type="radio"
                    id="RateAudioBefore1"
                    name="RateAudioBefore"
                    value="Excellent"
                    checked={values.RateAudioBefore === "Excellent"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>1</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="RateAudioBefore2">
                  <StyledInput
                    type="radio"
                    id="RateAudioBefore2"
                    name="RateAudioBefore"
                    value="Good"
                    checked={values.RateAudioBefore === "Good"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>2</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="RateAudioBefore3">
                  <StyledInput
                    type="radio"
                    id="RateAudioBefore3"
                    name="RateAudioBefore"
                    value="OK"
                    checked={values.RateAudioBefore === "OK"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>3</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="RateAudioBefore4">
                  <StyledInput
                    type="radio"
                    id="RateAudioBefore4"
                    name="RateAudioBefore"
                    value="Bad"
                    checked={values.RateAudioBefore === "Bad"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>4</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="RateAudioBefore5">
                  <StyledInput
                    type="radio"
                    id="RateAudioBefore5"
                    name="RateAudioBefore"
                    value="Very bad"
                    checked={values.RateAudioBefore === "Very bad"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>5</span>
                </StyledLabel>
              </li>
            </ul>
            <FeedbackScale2 />
            {errors.RateAudioBefore && touched.RateAudioBefore ? (
            <span className="error-message">{errors.RateAudioBefore}</span>
          ) : null}
        </StyledFormElementWrapper>

        <StyledFormElementWrapper>
          <StyledLabelHeader
            required
            className="radiolist"
            isEmpty={errors.RateAudioAfter && touched.RateAudioAfter}
            >How did you feel after?</StyledLabelHeader>
            <ul role="group" aria-labelledby="my-radio-group" className="rating-list">
              <li>
                <StyledLabel htmlFor="RateAudioAfter1">
                  <StyledInput
                    type="radio"
                    id="RateAudioAfter1"
                    name="RateAudioAfter"
                    value="Excellent"
                    checked={values.RateAudioAfter === "Excellent"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>1</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="RateAudioAfter2">
                  <StyledInput
                    type="radio"
                    id="RateAudioAfter2"
                    name="RateAudioAfter"
                    value="Good"
                    checked={values.RateAudioAfter === "Good"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>2</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="RateAudioAfter3">
                  <StyledInput
                    type="radio"
                    id="RateAudioAfter3"
                    name="RateAudioAfter"
                    value="OK"
                    checked={values.RateAudioAfter === "OK"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>3</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="RateAudioAfter4">
                  <StyledInput
                    type="radio"
                    id="RateAudioAfter4"
                    name="RateAudioAfter"
                    value="Bad"
                    checked={values.RateAudioAfter === "Bad"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>4</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="RateAudioAfter5">
                  <StyledInput
                    type="radio"
                    id="RateAudioAfter5"
                    name="RateAudioAfter"
                    value="Very bad"
                    checked={values.RateAudioAfter === "Very bad"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>5</span>
                </StyledLabel>
              </li>
            </ul>
            <FeedbackScale2 />
            {errors.RateAudioAfter && touched.RateAudioAfter ? (
            <span className="error-message">{errors.RatePainAfter}</span>
          ) : null}
        </StyledFormElementWrapper>


        <StyledFormElementWrapper>
          <StyledLabelHeader
            required
            className="radiolist"
            isEmpty={errors.howOftenListen && touched.howOftenListen}
            >How often do you listen to the audio?</StyledLabelHeader>
            <ul role="group" aria-labelledby="my-radio-group" className="modality-type">
              <li>
                <StyledLabel htmlFor="howOftenListenEveryFewDays">
                  <StyledInput
                    type="radio"
                    id="howOftenListenEveryFewDays"
                    name="howOftenListen"
                    value="Every few days"
                    checked={values.howOftenListen === "Every few days"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>Every few days</span>
                </StyledLabel>
              </li>
              <li>
                <StyledLabel htmlFor="howOftenListenEveryDay">
                <StyledInput
                    type="radio"
                    id="howOftenListenEveryDay"
                    name="howOftenListen"
                    value="Every day"
                    checked={values.howOftenListen === "Every day"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>Every day</span>
                </StyledLabel>
              </li>
              <li>
                <StyledLabel htmlFor="howOftenListenOnceWeek">
                <StyledInput
                    type="radio"
                    id="howOftenListenOnceWeek"
                    name="howOftenListen"
                    value="Once a week"
                    checked={values.howOftenListen === "Once a week"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>Once a week</span>
                </StyledLabel>
              </li>
              <li>
                <StyledLabel htmlFor="howOftenListenOnceMonth">
                <StyledInput
                    type="radio"
                    id="howOftenListenOnceMonth"
                    name="howOftenListen"
                    value="Once a month"
                    checked={values.howOftenListen === "Once a month"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>Once a month</span>
                </StyledLabel>
              </li>
            </ul>
            {errors.howOftenListen && touched.howOftenListen ? (
            <span className="error-message">{errors.howOftenListen}</span>
          ) : null}
        </StyledFormElementWrapper>




        <StyledFormElementWrapper>
          <StyledLabelHeader
            required
            className="radiolist"
            isEmpty={errors.AudioQuality && touched.AudioQuality}
            >How was the <strong>quality</strong> of the audio?</StyledLabelHeader>
            <ul role="group" aria-labelledby="my-radio-group" className="rating-list">
              <li>
                <StyledLabel htmlFor="AudioQuality1">
                  <StyledInput
                    type="radio"
                    id="AudioQuality1"
                    name="AudioQuality"
                    value="Excellent"
                    checked={values.AudioQuality === "Excellent"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>1</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="AudioQuality2">
                  <StyledInput
                    type="radio"
                    id="AudioQuality2"
                    name="AudioQuality"
                    value="Good"
                    checked={values.AudioQuality === "Good"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>2</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="AudioQuality3">
                  <StyledInput
                    type="radio"
                    id="AudioQuality3"
                    name="AudioQuality"
                    value="ok"
                    checked={values.AudioQuality === "ok"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>3</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="AudioQuality4">
                  <StyledInput
                    type="radio"
                    id="AudioQuality4"
                    name="AudioQuality"
                    value="Bad"
                    checked={values.AudioQuality === "Bad"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>4</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="AudioQuality5">
                  <StyledInput
                    type="radio"
                    id="AudioQuality5"
                    name="AudioQuality"
                    value="Very bad"
                    checked={values.AudioQuality === "Very bad"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>5</span>
                </StyledLabel>
              </li>
            </ul>
            <FeedbackScale2 />
            {errors.AudioQuality && touched.AudioQuality ? (
            <span className="error-message">{errors.AudioQuality}</span>
          ) : null}
        </StyledFormElementWrapper>

        <StyledFormElementWrapper>
          <StyledLabelHeader
            required
            className="radiolist"
            isEmpty={errors.AudioOverallExperience && touched.AudioOverallExperience}
            >I rate the meditation(s) as excellent</StyledLabelHeader>
            <ul role="group" aria-labelledby="my-radio-group" className="rating-list">
              <li>
                <StyledLabel htmlFor="AudioOverallExperience1">
                  <StyledInput
                    type="radio"
                    id="AudioOverallExperience1"
                    name="AudioOverallExperience"
                    value="Strongly agree"
                    checked={values.AudioOverallExperience === "Strongly agree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>1</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="AudioOverallExperience2">
                  <StyledInput
                    type="radio"
                    id="AudioOverallExperience2"
                    name="AudioOverallExperience"
                    value="Agree"
                    checked={values.AudioOverallExperience === "Agree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>2</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="AudioOverallExperience3">
                  <StyledInput
                    type="radio"
                    id="AudioOverallExperience3"
                    name="AudioOverallExperience"
                    value="Neuteral"
                    checked={values.AudioOverallExperience === "Neuteral"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>3</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="AudioOverallExperience4">
                  <StyledInput
                    type="radio"
                    id="AudioOverallExperience4"
                    name="AudioOverallExperience"
                    value="Disagree"
                    checked={values.AudioOverallExperience === "Disagree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>4</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="AudioOverallExperience5">
                  <StyledInput
                    type="radio"
                    id="AudioOverallExperience5"
                    name="AudioOverallExperience"
                    value="Strongly disagree"
                    checked={values.AudioOverallExperience === "Strongly disagree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>5</span>
                </StyledLabel>
              </li>
            </ul>
            <FeedbackScale />
            {errors.AudioOverallExperience && touched.AudioOverallExperience ? (
            <span className="error-message">{errors.AudioOverallExperience}</span>
          ) : null}
        </StyledFormElementWrapper>


        <StyledFormElementWrapper>
          <StyledLabelHeader
            required
            className="radiolist"
            isEmpty={errors.SuggestAudioToPeer && touched.SuggestAudioToPeer}
            >I would recommend this to a friend</StyledLabelHeader>
            <ul role="group" aria-labelledby="my-radio-group" className="rating-list">
              <li>
                <StyledLabel htmlFor="SuggestAudioToPeer1">
                  <StyledInput
                    type="radio"
                    id="SuggestAudioToPeer1"
                    name="SuggestAudioToPeer"
                    value="Strongly agree"
                    checked={values.SuggestAudioToPeer === "Strongly agree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>1</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="SuggestAudioToPeer2">
                  <StyledInput
                    type="radio"
                    id="SuggestAudioToPeer2"
                    name="SuggestAudioToPeer"
                    value="Agree"
                    checked={values.SuggestAudioToPeer === "Agree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>2</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="SuggestAudioToPeer3">
                  <StyledInput
                    type="radio"
                    id="SuggestAudioToPeer3"
                    name="SuggestAudioToPeer"
                    value="Neuteral"
                    checked={values.SuggestAudioToPeer === "Neuteral"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>3</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="SuggestAudioToPeer4">
                  <StyledInput
                    type="radio"
                    id="SuggestAudioToPeer4"
                    name="SuggestAudioToPeer"
                    value="Disagree"
                    checked={values.SuggestAudioToPeer === "Disagree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>4</span>
                </StyledLabel>
              </li>
              <li>
              <StyledLabel htmlFor="SuggestAudioToPeer5">
                  <StyledInput
                    type="radio"
                    id="SuggestAudioToPeer5"
                    name="SuggestAudioToPeer"
                    value="Strongly disagree"
                    checked={values.SuggestAudioToPeer === "Strongly disagree"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>5</span>
                </StyledLabel>
              </li>
            </ul>
            <FeedbackScale />
            {errors.SuggestAudioToPeer && touched.SuggestAudioToPeer ? (
            <span className="error-message">{errors.SuggestAudioToPeer}</span>
          ) : null}
        </StyledFormElementWrapper>

        <StyledFormElementWrapper>
          <StyledLabel htmlFor="audioFeebackComment">
            <StyledLabelHeader
            required
            >Any other comments?</StyledLabelHeader>
            <StyledTextArea
              id="audioFeebackComment"
              name="audioFeebackComment"
              type="text"
              onChange={handleChange}
              value={values.audioFeebackComment}
              placeholder="How was the overall experience? any other suggestions or comments?"
            />
          </StyledLabel>
          {errors.audioFeebackComment && touched.audioFeebackComment ? (
            <span className="error-message">{errors.audioFeebackComment}</span>
          ) : null}
        </StyledFormElementWrapper>
        </>
       }

    </StyledFieldset>
    <StyledButton
      type="submit"
      value="Send Message"
      disabled={isSubmitting || !isFormValid}
    >
      Submit
      </StyledButton>
    {messageSent && !isSuccessMessage && (
      <ErrorMessage>
        <p>Something went wrong please try again.</p>
      </ErrorMessage>
    )}
    </StyledForm>
    </>
  )
}

export default FeedbackForm


const PainReferenceScale = () => {
  return (
    <>
    <ul className="rating-pain-reference">
            <li>No pain</li>
            <li>Mild </li>
            <li>Moderate</li>
            <li>Severe </li>
            <li>Very severe</li>
            <li>Worst pain</li>
    </ul></>
  )
}

const FeedbackScale = () => {
  return ( 
    <ul className="rating-reference">
            <li>Strongly agree</li>
            <li>Agree</li>
            <li>Neuteral</li>
            <li>Disgree</li>
            <li>Strongly disgree</li>
    </ul>
  )
}

const FeedbackScale2 = () => {
  return ( 
    <ul className="rating-reference">
            <li>Excellent</li>
            <li>Good</li>
            <li>OK</li>
            <li>Bad</li>
            <li>Very bad</li>
    </ul>
  )
}