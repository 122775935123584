import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { handleUser } from '../utils/handleUser';

const PostPaymentHandler = ({ email, roleId, shouldProcess, setMessage, setMessageType }) => {
  const { isAuthenticated, user } = useAuth0();
  const [internalMessage, setInternalMessage] = useState('Processing...');

  useEffect(() => {
    const processUser = async () => {
      if (!shouldProcess) {
        setInternalMessage('Processing condition not met. Skipping user handling.');
        setMessage('Processing condition not met. Skipping user handling.');
        setMessageType('info');
        return;
      }

      try {
        if (isAuthenticated && user) {
          console.log('User is authenticated:', user);
          await handleUser(user.email, roleId, true);
          setInternalMessage('Role assigned successfully.');
          setMessage('Role assigned successfully.');
          setMessageType('success');
        } else {
          console.log('User is not authenticated. Creating new user.');
          await handleUser(email, roleId, true);
          setInternalMessage('Please check your email for a verification link.');
          setMessage('Please check your email for a verification link.');
          setMessageType('success');
        }
      } catch (error) {
        console.error('Error processing user:', error);
        setInternalMessage('An error occurred while processing your request. Please try again.');
        setMessage('An error occurred while processing your request. Please try again.');
        setMessageType('error');
      }
    };

    processUser();
  }, [isAuthenticated, user, email, roleId, shouldProcess, setMessage, setMessageType]);

  return <div>{internalMessage}</div>;
};

export default PostPaymentHandler;