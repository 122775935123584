import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import LayoutNoStripe from "../components/layoutNoStripe";
import {
  Article,
  ArticleHeader,
  ArticleSection,
} from "../styledComponents/article";

const PaymentSuccessAudioProgram = ({ data, location }) => {
  const [customer, setCustomer] = useState({});
  const [customerFirstName, setCustomerFirstName] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();

  const getManagementApiToken = async () => {
    const url = `https://${process.env.GATSBY_AUTH0_DOMAIN}/oauth/token`;
    const payload = {
      client_id: process.env.GATSBY_AUTH0_MGMT_CLIENT_ID,
      client_secret: process.env.GATSBY_AUTH0_MGMT_CLIENT_SECRET,
      audience: `https://dev-e2-juiwx.us.auth0.com/api/v2/`,
      grant_type: "client_credentials",
    };

    try {
      const response = await axios.post(url, payload);
      const token = response.data.access_token;

      if (!token) {
        console.error("Management API token is missing.");
        throw new Error("Management API token retrieval failed.");
      }

      console.log("Management API token retrieved:", token);
      return token;
    } catch (error) {
      console.error("Error fetching Management API token:", error.response?.data || error.message);
      throw error;
    }
  };

  const generateStrongPassword = () => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
    let password = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      password += charset.charAt(Math.floor(Math.random() * n));
    }
    return password;
  };

  const manageAuth0Roles = async (email, productType, firstName, lastName) => {
    try {
      const token = isAuthenticated
        ? await getAccessTokenSilently()
        : await getManagementApiToken();

      if (!token) {
        console.error("Token is missing or invalid.");
        throw new Error("Authentication token is not available.");
      }

      console.log("Using token for Auth0 roles:", token);

      const tempPassword = isAuthenticated ? null : generateStrongPassword();
  
      await assignRoleOrCreateUser(email, productType, firstName, lastName, token, tempPassword);

      if (isAuthenticated) {
        console.log("User is authenticated. Redirecting to /my-account.");
        navigate("/my-account");
      } else {
        console.log("New user account created. Logging in with temporary password.");
        console.log("Attempting loginWithRedirect with redirectUri:", "https://www.karinagrant.co.uk/my-account");
        // Programmatically log in the user using Resource Owner Password Flow
        const loginResponse = await axios.post(`https://${process.env.GATSBY_AUTH0_DOMAIN}/oauth/token`, {
          grant_type: "password",
          username: email,
          password: tempPassword,
          audience: `https://dev-e2-juiwx.us.auth0.com/api/v2/`,
          client_id: process.env.GATSBY_AUTH0_MGMT_CLIENT_ID,
          client_secret: process.env.GATSBY_AUTH0_MGMT_CLIENT_SECRET,
          connection: "Username-Password-Authentication", // Specify the database connection
        });
  
        if (loginResponse.data.access_token) {
          console.log("User logged in successfully. Redirecting to /my-account.");
          navigate("/my-account");
          setMessage("Account created. You have been logged in successfully.");
          setMessageType("success");
        } else {
          console.error("Failed to log in user programmatically.");
          setMessage("Account created, but login failed. Please log in manually.");
          setMessageType("error");
        }
      }
    } catch (error) {
      console.error("Error managing Auth0 roles:", error);
      setMessage("Error: Unable to manage Auth0 roles.");
      setMessageType("error");
    }
  };
  

  const assignRoleOrCreateUser = async (email, productType, firstName, lastName, token, tempPassword = null) => {
    if (!token) {
      console.error("Token is missing.");
      throw new Error("Management API token is not available.");
    }

    const managementApiUrl = `https://${process.env.GATSBY_AUTH0_DOMAIN}/api/v2/users-by-email?email=${email}`;
    console.log("assignRoleOrCreateUser: managementApiUrl", managementApiUrl);

    try {
      const response = await fetch(managementApiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const users = await response.json();

      if (users.length > 0) {
        const user = users[0];
        console.log("User exists:", user);

        const roleId = productType;
        const assignRoleResponse = await fetch(
          `https://${process.env.GATSBY_AUTH0_DOMAIN}/api/v2/users/${user.user_id}/roles`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ roles: [roleId] }),
          }
        );

        if (!assignRoleResponse.ok) {
          const roleErrorData = await assignRoleResponse.json();
          throw new Error(roleErrorData.message);
        }
      } else {
        console.log("User does not exist - creating account.");
        const newUserPayload = {
          email,
          given_name: firstName,
          family_name: lastName,
          password: tempPassword,
          connection: "Username-Password-Authentication",
        };

        const createUserResponse = await fetch(
          `https://${process.env.GATSBY_AUTH0_DOMAIN}/api/v2/users`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newUserPayload),
          }
        );

        if (!createUserResponse.ok) {
          const errorData = await createUserResponse.json();
          console.error("Error creating user:", errorData);
          throw new Error(errorData.message);
        }

        const createdUser = await createUserResponse.json();
        console.log("User created successfully:", createdUser);

        const roleId = productType;
        const assignRoleResponse = await fetch(
          `https://${process.env.GATSBY_AUTH0_DOMAIN}/api/v2/users/${createdUser.user_id}/roles`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ roles: [roleId] }),
          }
        );

        if (!assignRoleResponse.ok) {
          const roleErrorData = await assignRoleResponse.json();
          console.error("Error assigning role:", roleErrorData);
          throw new Error(roleErrorData.message);
        }
      }
    } catch (error) {
      console.error("Error in assignRoleOrCreateUser:", error);
      throw error;
    }
  };

  const getStripeSession = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get("sessionID");

    if (!sessionId) {
      setMessage("Error: Session ID not found in URL.");
      setMessageType("error");
      return;
    }

    try {
      const response = await fetch(`/.netlify/functions/stripe-customer?sessionID=${sessionId}`);
      const data = await response.json();
      console.log("Stripe session data:", data);
      console.log("Auth0 isAuthenticated:", isAuthenticated);
      console.log("Auth0 user:", isAuthenticated ? isAuthenticated.user : null);
      console.log("Auth0 user email:", isAuthenticated ? isAuthenticated.user.email : null);
      console.log("Auth0 user name:", isAuthenticated ? isAuthenticated.user.name : null);
      console.log("Auth0 user given_name:", isAuthenticated ? isAuthenticated.user.given_name : null);
      console.log("Auth0 user family_name:", isAuthenticated ? isAuthenticated.user.family_name : null);
      console.log("Auth0 user email_verified:", isAuthenticated ? isAuthenticated.user.email_verified : null);

      if (data && data.customer && data.productsList) {
        setCustomer(data.customer);

        const fullName = data.customer.name || "";
        const firstName = fullName.split(" ")[0];
        setCustomerFirstName(firstName);

        const email = data.customer.email;
        const productType = data.productsList[0]?.metadata?.auth0_role || "default";

        await manageAuth0Roles(email, productType, firstName, fullName.split(" ")[1] || "");
      } else {
        console.error("Invalid data structure:", data);
        setMessage("Error: Invalid data received from Stripe.");
        setMessageType("error");
      }
    } catch (error) {
      console.error("Error fetching Stripe session:", error);
      setMessage("Error: Unable to fetch session data.");
      setMessageType("error");
    }
  };

  useEffect(() => {
    if (isAuthenticated !== undefined) {
      getStripeSession();
    }
  }, [isAuthenticated]);

  return (
    <LayoutNoStripe displayNavBorder location={location}>
      <Article itemScope itemType="http://schema.org/Article">
        <ArticleHeader noMargin>
          <h1 itemProp="headline">Payment Success</h1>
        </ArticleHeader>
        <ArticleSection itemProp="articleBody">
          {message && <div className={`message ${messageType}`}>{message}</div>}
          <p>
            Thank you {customerFirstName || "Customer"} for your purchase! We
            are processing your order. You shall receive an email from Tracy
            within the next 24 hours.
          </p>
        </ArticleSection>
      </Article>
    </LayoutNoStripe>
  );
};

export default PaymentSuccessAudioProgram;
