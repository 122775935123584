import React, { useState, useLayoutEffect, useEffect } from "react"
import { Link } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react";
import {
  ArticleAuthorInfo,
  ArticleDate,
} from "../../styledComponents/article"
import LayoutNoStripe from "../../components/layoutNoStripe"

import {
  StyledVideoTestimonialArticle,
  StyledVideoTestimonialContent,
  StyledVideoTestimonialHeader,
  StyledVideoTestimonialLede,
  StyledVideoTestimonialVideo
} from "../../styledComponents/videoTestimonial"


const QuantumTouchEnergyBoostWorkshop = ({ data, location }) => {
  const { isAuthenticated, user, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
  const [isAutopPlay, setAutoPlay] = useState(false);
  const [roles, setRoles] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(true);
  console.log('user', user)
  console.log('roles', roles)

  const fetchRoles = async () => {
    if (!isAuthenticated) {
      setRoles([]);
      setLoadingRoles(false);
      return;
    }

    try {
      const userRoles = user?.["https://www.karinagrant.co.uk/roles"] || [];

      if (userRoles.length > 0) {
        setRoles(userRoles);
      } else {
        const token = await getAccessTokenSilently({ ignoreCache: true });
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const tokenRoles = decodedToken["https://www.karinagrant.co.uk/roles"] || [];
        setRoles(tokenRoles);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
      setRoles([]);
    } finally {
      setLoadingRoles(false);
    }
  };

  useEffect(() => {
    fetchRoles();

    const interval = setInterval(() => {
      fetchRoles();
    }, 15 * 1000);

    return () => clearInterval(interval);
  }, [isAuthenticated, user, getAccessTokenSilently]);

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      const urlParams = new URLSearchParams(window.location.search)
      const autoplay = urlParams.get('autoplay')
      setAutoPlay(autoplay)
      return
    }
  }, [])
  return (
    <LayoutNoStripe displayNavBorder location={location} hideNewsletterSignUp>
      {console.log('isAuthenticated', isAuthenticated)}
      {isAuthenticated && (roles?.includes('Energy Boost Workshop')) ?
        <StyledVideoTestimonialArticle itemScope itemType="http://schema.org/Article">
          <StyledVideoTestimonialHeader>
            <h1 itemProp="headline">Quantum-Touch Energy Boost Workshop </h1>
            <ArticleAuthorInfo className="author"><span>Taught by <Link to="/about">Karina Grant</Link></span> </ArticleAuthorInfo>
            <ArticleDate offScreen dateTime=''>Last updated on November 12th 2024</ArticleDate>
          </StyledVideoTestimonialHeader>

          <StyledVideoTestimonialVideo>

            <figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio"><div class="wp-block-embed__wrapper"><iframe loading="lazy" title="A 2-hour Quantum-Touch Energy Boost Workshop taught by Karina Grant" width="100%" height="100%" src={'https://karinagrant.s3.eu-west-1.amazonaws.com/quantum-touch/EnergyBoostWorkshopNovember.mp4'} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" controlsList="nodownload" allowfullscreen=""></iframe></div></figure>
          </StyledVideoTestimonialVideo>
        </StyledVideoTestimonialArticle>
        :
        <StyledVideoTestimonialArticle itemScope itemType="http://schema.org/Article">
          <StyledVideoTestimonialHeader>
            <h1 itemProp="headline">Quantum-Touch Energy Boost Workshop </h1>
            <ArticleAuthorInfo className="author"><span>Taught by <Link to="/about">Karina Grant</Link></span> </ArticleAuthorInfo>
            <ArticleDate offScreen dateTime=''>Last updated on November 12th 2024</ArticleDate>
          </StyledVideoTestimonialHeader>
          <StyledVideoTestimonialLede>
            <p>Please <Link to="/contact">get in contact</Link> - you do not have access to this recording</p>
          </StyledVideoTestimonialLede>
        </StyledVideoTestimonialArticle>
      }
    </LayoutNoStripe>
  )
}

export default QuantumTouchEnergyBoostWorkshop

